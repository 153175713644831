import React from 'react'
import Layout from '../../../components/layout'
import { Link } from 'gatsby'

const Mental = () => (
  <Layout>
    <section className="section-padding-large biq-bg-prim biq-text-white">
      <div className="container">
        <h1>Mental Health</h1>
      </div>
    </section>
    <section className="section-padding biq-bg-white biq-text-black select-none">
      <div className="container">
        <h2 className="text-left mt-6">Stress & Anxiety</h2>
        <div className="flex biq-text-md mt-6">
          There is plenty of research showing that breathing affects your
          emotions, and emotions affect your breathing (there is even a study
          that shows your facial expressions affects your oxygen intake!). Where
          you breathe from and the pace of your breath affect your nervous
          system and biochemistry. In short, breathing correctly supports your
          being able to switch from calm to alert to excited to relaxed, rather
          than stay on code red all day.
        </div>
        <div className="flex biq-text-md mt-6">
          A tremendously important adjust to these recommendations are
          correcting the mechanics of the breath so that a more controlled
          breath becomes natural. A biomechanically sound breath also means the
          exhale is more efficient, which is critical in taking an efficient
          inhale and completely taking a full exhale.{' '}
        </div>
        <div className="flex biq-text-md mt-6">
          <span>
            A certain amount of stress can be good for you, it can be positive,
            challenging and inspire learning and change. Most of us however, are
            taking on too much stress and not taking the time to “metabolize”
            it. Breathing exercises help to get it out of your system.
          </span>
        </div>
        <div className="flex biq-text-md mt-6">
          <span className="text-red-600">
            Part of the problem is that we live in a society that glorifies
            stress. Are you a stress addict? Take the quiz on p. 144 from
            BREATHE.
          </span>
        </div>
        <div className="flex biq-text-md mt-6">
          <span>
            At some point you hit a wall. Your stress gets unmanageable, you
            make a mistake because you are tired, you start self medicating.
            your symptoms get worse and it starts to impact on your quality of
            life or relationships.
          </span>
        </div>
      </div>
    </section>
    <section
          id="biqMockup"
          className="section-padding biq-bg-gray"
          // style={{
          //   background: `url(${this.props.data.biqBackground.edges[0].node.childImageSharp.fluid.src})`,
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          //   backgroundBlendMode: 'darken',
          //   backgroundPositionY: '1',
          // }}
        >
          <div className="flex absolute">
            {/* <div className="w-full">
              <Img
                fluid={
                  this.props.data.biqBackground.edges[0].node.childImageSharp
                    .fluid
                }
              />
            </div> */}
          </div>
          <div className="container">
            {/* BIQ  */}
            <div className="flex flex-row flex-wrap my-24">
              {/* <div className="flex w-full xl:w-1/2"> */}
              {/* <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.biqMobile.edges[0].node.childImageSharp
                        .fluid
                    }
                  />
                </div> */}
              {/* </div> */}
              <div className="flex flex-col w-full place-items-center justify-center">
                <h1 className="uppercase biq-text-prim">Take the BIQ</h1>
                <h2 className="flex font-bold my-6 biq-text-xl text-center  biq-text-prim">
                  You can't change what you don't measure
                </h2>
                <span className="flex font-normal my-6">
                  <Link to="/biq" className="btn-prim biq-text-white">
                    Find Out Your BreathingIQ
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </section>
  </Layout>
)

export default Mental
